import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import React, { useEffect } from 'react';


import RegPage from './RegPage';
import UnderConstruction from './UnderConstruction'; // Import the Under Construction page
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary
import NotFound from './NotFound';
import Forum from './Forum';
import RegisterEvent from './RegisterEvent';
import Feedback from './Feedback';


function App() {
  useEffect(() => {
    // After a delay, remove the landscape orientation class
    setTimeout(() => {
      const landscapeView = document.querySelector('.landscape-view');
      if (landscapeView) {
        landscapeView.style.transform = 'none';
        landscapeView.style.width = 'auto';
        landscapeView.style.height = 'auto';
        landscapeView.style.overflow = 'visible';
        landscapeView.style.position = 'static';
        landscapeView.style.backgroundColor = 'transparent';
        landscapeView.style.zIndex = 'auto';
        landscapeView.style.transition = 'none';
      }
    }, 1000); // Adjust the delay (in milliseconds) as needed
  }, []);
  return (
    <Router>
            <ErrorBoundary>

      <Routes>
        <Route path="/" element={<RegPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/under-construction" element={<UnderConstruction />} /> {/* Define the Under Construction route */}
        <Route path="/Forum" element={<Forum />} /> {/* Define the Under Construction route */}
        <Route path="/duringEvent" element={<RegisterEvent />} /> {/* Define the Under Construction route */}
        <Route path="/Feedback" element={<Feedback />} /> {/* Define the Under Construction route */}

        
        
        {/* Add more Route components for additional pages */}
      </Routes>
      </ErrorBoundary>

    </Router>
  );
}


export default App;
