import React, { useState, useEffect } from 'react';
import { TextField, CardContent,
Button,CssBaseline, Grid,Container, LinearProgress,Paper, MenuItem, FormControl, InputLabel, Select, Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReportProblem from './ReportProblem';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import axios from 'axios';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import moment from 'moment'; // For formatting the date and time

const theme = createTheme({
  palette: {
    primary: {
      main: "#013220", // Main color
    },
  },
});
////
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: 'clamp(1.2rem, 4vw, 2.5rem)', // Responsive font size
  color: 'transparent',
  background: 'linear-gradient(135deg, #013220 0%, #004d40 50%, #00796b 100%)', // Gradient with related shades of green
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#013220', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const BackgroundContainer = styled('div')({
  minHeight: '100vh',
  width: '100vw', // Ensure full width
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '20px',
  background: 'linear-gradient(135deg, #013220 0%, #004d40 50%, #00796b 100%)', // Gradient with related shades of green
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
  '::before': {
    content: '""',
    position: 'absolute',
    top: '-50px',
    left: '-50px',
    width: '300px',
    height: '300px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur
    borderRadius: '50%',
    zIndex: -1,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: '-100px',
    right: '-100px',
    width: '200px',
    height: '200px',
    background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur on the opposite side
    borderRadius: '50%',
    zIndex: -1,
  },
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '800px',
  margin: 'auto',
  background: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white background
  padding: '20px',
  borderRadius: '20px', // Smooth rounded corners
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Soft and professional shadow
  position:"relative"
});

// Adjusted Button Style
const StyledButton = styled(Button)({
  marginTop: '10px',
  padding: '12px 20px',
  background: 'linear-gradient(135deg, #013220 0%, #004d40 50%, #00796b 100%)', // Gradient with related shades of green
  color: '#fff',
  '&:hover': {
    background: 'linear-gradient(135deg, #013220 0%, #004d40 50%, #00796b 100%)', // Gradient with related shades of green
  },
  transition: 'background-color 0.3s',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderRadius: '30px',
});


const convertArabicToEnglish = (input) => {
  const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
  const englishNumbers = '0123456789';
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const index = arabicNumbers.indexOf(input[i]);
    output += index !== -1 ? englishNumbers[index] : input[i];
  }
  return output;
};

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    mobile: "",
    company: "",
    whichday: '',
    attend: "No",
    event_id:"1",
    dateTime: moment().format('MMMM Do YYYY, h:mm:ss a')
  });

  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [language, setLanguage] = useState('en');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(false);

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'ar' : 'en');
  };


  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Agri_TECH.Forum);
    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const convertedValue = convertArabicToEnglish(value);
    setFormData({ ...formData, [name]: convertedValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (formData.whichday === '' || formData.mobile === '') {
      setFeedback({
        message: 'Please select an option for all fields.',
        type: 'error',
      });
      return;
    }
    setProgress(true);


    try {
      const response = await fetch('https://agritech-backend-768fe146b7a5.herokuapp.com/api/registrationsForum', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        setFeedback({
          message: translations[language].successMessage,
          type: 'success',
        });
      } else if (response.status === 400) {
        setIsSubmitting(false);
        setFeedback({
          message: translations[language].alreadyMessage,
          type: 'error',
        });
      } else {
        setFeedback({
          message: translations[language].errorMessage,
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: translations[language].errorMessage,
        type: 'error',
      });
    }
    setProgress(false);

  };

  const translations = {
    en: {
      Language: "عربي",
      companyLabel: "Company",
      registrationForm: "Registration Forum",
      firstNameLabel: "First Name",
      titleLabel: "Job Title",
      lastNameLabel: "Last Name",
      emailLabel: "Email",
      mobileLabel: "Mobile",
      howDidYouKnowLabel: "Which Day to attend?",
      submitButtonLabel: "Submit",
      successMessage: "Thank you for registering!",
      errorMessage: "An error occurred while processing the form.",
      alreadyMessage: "You have already registered with this Email",
      howDidYouKnowOptions: [
        { value: 'Day 1', label: 'Day 1' },
        { value: 'Day 2', label: 'Day 2' },
        { value: 'Day 1 & Day 2', label: 'Day 1 & Day 2' },
      ],
    },
    ar: {
      Language: "English",
      companyLabel: "الشركة",
      registrationForm: "التسجيل",
      firstNameLabel: "الاسم الأول",
      titleLabel: "العمل",
      lastNameLabel: "الاسم الأخير",
      emailLabel: "البريد الإلكتروني",
      mobileLabel: "الجوال",
      howDidYouKnowLabel: "أي يوم للحضور؟",
      submitButtonLabel: "إرسال",
      successMessage: "شكرًا لتسجيلك!",
      errorMessage: "حدث خطأ أثناء معالجة النموذج.",
      alreadyMessage: "لقد قمت بالتسجيل بهذا البريد الالكتروني مسبقا",
      howDidYouKnowOptions: [
        { value: 'Day 1', label: 'اليوم الاول' },
        { value: 'Day 2', label: 'اليوم الثاني' },
        { value: 'Day 1 & Day 2', label: 'اليوم الاول و الثاني' },
      ],
    },
  };

  return (

    <div>

    {formStates && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              width: '400px'
            }}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                severity="error"
                style={{ display: 'flex', alignItems: 'center' }}
              >
    Registration is Currently Closed         
    </Alert>
            </div>
          )}
    
    <div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
    
    <BackgroundContainer>
     <div style={{marginTop:"50px",marginBottom:"50px", width:"90vw"}} className="form-menu-container">
          <CssBaseline />

      <StyledCard>
      <CardContent>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <ThemeProvider theme={theme}>
                  <Button onClick={toggleLanguage} style={{ fontSize: '18px', padding: '10px 20px' }}>
                    {translations[language].Language}
                  </Button>
                </ThemeProvider>
              </div>
              <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
   // position: 'absolute', // Corrected position value
    zIndex: 1200, // Ensures the menu 
  }}
>
<ReportProblem dynamicNumber="Agritech Form" />
          </IconButton>
        </Tooltip>
        </Box>

      <Box display="flex" justifyContent="center" mb={2}>
          <img src="./agritech-logo-low.png" alt="Mining-Tech Logo" style={{ width: '200px' }} />
        </Box>  
        <Box sx={{ textAlign: 'center', mt: 4 }}>

       <StyledTitle variant="h5" gutterBottom>

    {translations[language].registrationForm}
    </StyledTitle>
     <Typography sx={{ color: '#013220',marginBottom:"10px" }}>
    28th - 30th October, 2024
  </Typography>
</Box>

        <ThemeProvider theme={theme}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
              <TextField
                label={translations[language].firstNameLabel}
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                fullWidth
              />
              </Grid>
                        <Grid item xs={12} sm={6}>

              <TextField
                label={translations[language].lastNameLabel}
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                fullWidth
              />
</Grid>
                        <Grid item xs={12} sm={6}>
              <TextField
                label={translations[language].emailLabel}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
              />

</Grid>
                        <Grid item xs={12} sm={6}>
              <TextField
                label={translations[language].mobileLabel}
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                fullWidth
              />
              </Grid>
                        <Grid item xs={12} sm={6}>
              <TextField
                label={translations[language].companyLabel}
                name="company"
                value={formData.company}
                onChange={handleChange}
                required
                fullWidth
              />
</Grid>

<Grid item xs={12} sm={6}>
              <TextField
                label={translations[language].titleLabel}
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                fullWidth
              />
              </Grid>
                        <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>{translations[language].howDidYouKnowLabel}</InputLabel>
                <Select
                  label={translations[language].howDidYouKnowLabel}
                  name="whichday"
                  value={formData.whichday}
                  onChange={handleChange}
                >
                  {translations[language].howDidYouKnowOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
              </Grid>

               <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting || formStates}
            >
              {translations[language].submitButtonLabel}
            </StyledButton>
          </form>
          {progress && <LinearProgress sx={{mt:2,mb: 2 }} />}
        </ThemeProvider>
        {feedback.message && (
          <Typography
            variant="body1"
            align="center"
            sx={{ color: feedback.type === 'success' ? 'green' : 'red', marginTop: '20px' }}
          >
            {feedback.message}
          </Typography>
        )}
      </CardContent>

      </StyledCard>
        </div>
     </BackgroundContainer>
     </div>
     </div>


  );
};

export default RegistrationForm;
